
















































































import Vue  from "vue";
import { getModule } from "vuex-module-decorators";

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import Spinners from "@/components/utilities/Spinners.vue";
import AnalyticsDateControl from "./AnalyticsDateControl.vue";

import { transformDataToCsvUrl } from "@/services/csv";

import { AnalyticsPollOverview } from "@/types/interfaces";

export default Vue.extend ({
    components: {
        Spinners,
        AnalyticsDateControl
    },
    computed: {

        loading(): boolean {
            return analyticsStore.loading;
        },
    
        polls(): Array<AnalyticsPollOverview> {
            return analyticsStore.polls;
        },
    
        sortedVisits(): Array<AnalyticsPollOverview> {
            return this.sortedVisitsMethod();
        },
    
        tableHeaders(): Array<string> {
            return [
                "Session ID",
                "Session Name",
                "Total Responses",
                "Unique Respondents"
            ];
        },
    
        tableFooter(): Array<string> {
            return ["Total", "", `${this.totalHits}`, `${this.totalUniques}`];
        },
    
        totalHits(): number {
            return this.polls.reduce((acc, curr) => {
                return (acc += curr.hits);
            }, 0);
        },
    
        totalUniques(): number {
            return this.polls.reduce((acc, curr) => {
                return (acc += curr.uniques);
            }, 0);
        },
    
        csvUrl(): string {
            return this.getCsvUrl();
        }
    },
    methods: {
        sortedVisitsMethod() {
            return this.polls.sort((a, b) => b.hits - a.hits);
        },
        getData() {
            analyticsStore.getPolls();
        },
    
        getCsvUrl() {
            const valuesOnly = this.polls.map((item) => [
                item.sessionId,
                item.sessionName,
                item.hits ? `${item.hits}` : "0",
                item.uniques ? `${item.uniques}` : "0"
            ]);
            const dataArray = [this.tableHeaders, ...valuesOnly, this.tableFooter];
    
            return transformDataToCsvUrl(dataArray);
        }
    },
    mounted() {
        this.getData();
    }

})
