








































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import AnalyticsDateControl from "./AnalyticsDateControl.vue";

import { transformDataToCsvUrl } from "@/services/csv";
import { AnalyticsPollData, AnalyticsPollSingle, AnalyticsPollVisitor } from "@/types/interfaces";


export default Vue.extend ({
    components: {
        AnalyticsDateControl
    }
    ,computed: {

        loading(): boolean {
            return analyticsStore.loading;
        },
    
        sessionId(): string {
            return this.$route.params.sessionId ? this.$route.params.sessionId : "";
        },
    
        itemData(): AnalyticsPollSingle {
            return analyticsStore.singlePoll;
        },
    
        users(): Array<AnalyticsPollVisitor> {
            return this.itemData.users;
        },
    
        sessionName(): string {
            return this.itemData.sessionName;
        },
    
        tableHeaders(): Array<string> {
            return [
                "Attendee Id",
                "First Name",
                "Last Name",
                "Company Name",
                "Email",
                ...this.flatQuestions
            ];
        },
    
        sortedPolls(): Array<AnalyticsPollData> {
            return [...this.itemData.pollData].sort((a, b) => {
                const aId = parseInt(a.pollId);
                const bId = parseInt(b.pollId);
    
                return aId - bId;
            });
        },
    
        flatQuestions(): Array<string> {
            const questions = [] as Array<string>;
            const polls = this.sortedPolls;
    
            polls.forEach((poll) => {
                poll.questions.forEach((question: any) => {
                    questions.push(question.title ? question.title : question.name);
                });
            });
    
            return questions;
        },
    
        userRecords(): Array<any> {
            return this.users.map((user) => {
                const flatData = [
                    `${user.uid}`,
                    user.firstName ? `${user.firstName}` : "",
                    user.lastName ? `${user.lastName}` : "",
                    user.companyName ? `${user.companyName}` : "",
                    user.email ? `${user.email}` : ""
                ];
    
                const polls = this.sortedPolls;
    
                polls.forEach((poll) => {
                    const myResponse = user.polls.find(
                        (myPoll) => myPoll.pollId === poll.pollId
                    );
    
                    poll.questions.forEach((question: any) => {
                        const myAnswer =
                            myResponse && myResponse.answers[question.id]
                                ? `${myResponse.answers[question.id]}`
                                : "";
                        const otherKey = `${question.id}-Comment`;
                        const answerOther =
                            myResponse && myResponse.answers[otherKey]
                                ? `${myResponse.answers[otherKey]}`
                                : "";
    
                        const fullAnswer = answerOther
                            ? `${myAnswer} (${answerOther})`
                            : myAnswer;
    
                        flatData.push(fullAnswer);
                    });
                });
    
                return flatData;
            });
        },
    
        csvUrl(): string {
            return this.getCsvUrl();
        },
    
        totalVisitors(): number {
            return this.users.length;
        }
    },
    methods: {
        getCsvUrl() {
            const dataArray = [this.tableHeaders, ...this.userRecords];
    
            return transformDataToCsvUrl(dataArray);
        },
    
        getData() {
            analyticsStore.getPollSingle(this.sessionId);
        }
    },

    mounted() {
        this.getData();
    }

})
